import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(true);
  const [isTyping, setIsTyping] = useState(true);
  

  const fullText = `<p>
        On the 21st August, fighting through tear induced blurred vision, I typed out my suicide note. Things finally became too much. The toll launching my own token took on me cost me a marriage, my life, my health and my sleep. From dreams of revolutionizing finance to the depths of despair.
        </p>
        <p>
        So how did I get here?
        </p>
        <p>
        When I first entered this space as a developer, I truly believed that we were changing the world, we were pioneers decentralizing archaic systems and bringing forth a new beginning for the world in the backdrop of corruption, bureaucracy and authoritarianism.
        </p>
        <p>
        I believed that if I built a good product, launched a token with utility where individuals could use it within an ecosystem, then this would accrue value organically from the others in this industry who believe in the same vision as me. At the time our token launched, we did not have our utility live, nor did we have anything beyond a basic website, however we did have a supportive community, a great vision, and a hardworking selfless team. 
        </p>
        <p>
        I believed that if I shared my background, showed my face and gave myself wholly and truly to the community and the project then this would count for something. This would show I am here and committed to success. My project never had an investment deck nor did we ever seek external investment. Due to our own organic marketing strategies we had a few private investors contact us and raised a total throughout our lifetime of less than $400,000 private investment. We raised this at the same valuation that our public launch was valued at to make sure public weren’t at a disadvantage. We also locked our private investors with up to 18 months linear vesting. Our public launch raised just under $2,000,000 with circa 50% locked for over a year, a 4 month cliff for the team and 5+ years vesting. 
        </p>
        <p>
        My first mistake came when I accepted a market maker referred to me by the exchange. I saw the $300,000 they were given evaporate as they washed, without permission or consent, up to $20,000,000 worth of 24 hour volume day to day. Even at 0.01% fees this level of volume cost us dearly. I was not even aware wash trading was a thing in crypto, but I soon learnt that lesson. The second lesson I learnt was when the CEX we IEO’d on offered us to be listed for margin trading. Thinking this would add value I accepted and I soon learnt my first dirty trick from exchanges. 
        </p>
        <p>
        Listing you for margin, then shorting you themselves whilst dumping your price with the tokens users had on the exchange. Even with this experience and with the community being explained the issue, the FUD was immense, even though we had no tokens to sell, we were accused of selling. Even though an exchange had dumped the price we were pressured to list on more. 
        </p>
        <p>
        So we did & listed on our first two “Tier one CEXs” one is still considered a tier one & the other is now gone. Bear in mind this was years ago, and the latter that failed we soon learnt was a joke with almost zero organic traders. The first, although having organic volume, also trade your tokens themselves and have their own market makers on their exchange washing fake volume and trading against even spot clients. For the privilege of these listings it cost us over $500,000. We secured both of these before our 1 month TGE anniversary.
        </p>
        <p>
        But of course, in crypto the community always need more. I spoke with one of our VCs after receiving an offer from KuCoin. This offer was a $250,000 security deposit, $250,000 fee and $100,000 in marketing tokens. Of course, this amount of money we saw as insane, and he agreed, telling me that paying for listings isn’t necessary and recommended OKex (now OKX). He said if you get close to them they will list you for free! Great I thought! At this point our token was doing very well and had got to over 100M market cap.
        </p>
        <p>
        So we joined a group with them & bridged liquidity to OEC chain and launched on jswap, we partnered with multiple OEC protocols and took part in as much OKex promotional activities as we could, their chain, twitter spaces, web3 wallet… but yet the response to any questions on listings was that it is a separate team and unrelated. Now this is true, their are supposed to be “Chinese Walls” in place, but there is a certain expectation of quid pro quo that they heavily implied. This gave these exchanges the perfect get out of jail free cards. 
        </p>
        <p>
          You can’t complain as we told you they were unrelated! But what about all this effort we went to! “What about it?” we were told. We needed hype, and so we paid $300,000 in tether, plus token rewards on top to be featured as a marketing sponsor of a priment crypto business. This package got us marketing and coverage by Japanese, Nigerian, Turkish and many other nationalities to grow our exposure and grow our social media presence. Our token dumped throughout it all.
        </p>
        <p>
        Now comes the bear market of 2022. The hype fades and our token begins its descent (alongside Bitcoin, Ethereum and all alts). We went from a top 250 token to singular millions. New accounts send me death threats everyday as fast as I can block them and like an idiot I am throwing money at the chart to try and keep the community happy. This is all to no avail. I spent a total of $878,723.11 and my token still fell 93% from all time highs.
        </p>
        <p>
        After blowing close to a million trying to recover the chart myself I decided to go back to KuCoin to take them up on their listing offer. After having to strong arm them due to the listing managers hurt feelings (they are obviously used to desperation from tokens) they agreed to (almost) offer the same terms… the security deposit had increased from $250,000 to $300,000 in tether and had to be locked for two years. On top of this we had to pay a fee of $250,000 tether and another $100,000 in $VEMP. Now anybody who has listed a token on Kucoin will know that this security deposit… is not a security deposit. If you fail any one of these, they take 50% of your money;
        </p>
        <p>
        <p>
  <em>
    Order book Maintenance:
    <ul>
      <li>If the spread is greater than 1.5% or less than 10 orders booked on each side of order book;</li>
      <li>Less than USDT 20,000 worth of orders are booked on each side of the order book;</li>
      <li>If failed to meet the requirements above, the order book must be resumed to these required levels within 30 minutes.</li>
    </ul>
  </em>
</p>
<p>
  <em>
    Trading Volume:
    <ul>
      <li> a) Within 60 days after the listing date, the daily trading volume is less
      than USDT 300,000; above 60 days, the daily trading volume is less than
      USDT 200,000.</li>
    </ul>
  </em>
</p>
<p>
  <em>
    Price Volatility:
    <ul>
      <li>a) If the price declines below 80% of the opening market price over 2
      hours within one months after the listing date;</li>
      <li>c) If within 24 hours, the price decreasing beyond 30% and stays under
      this rate (beyond 30%) for over 15 fifteen minutes;</li>
      <li>d) If within 72 hours, the price decreasing beyond 50% and stays under
      this rate (beyond 50%) for over fifteen minutes;</li>
    </ul>
  </em>
</p>
        </p>
        <p>
        Now, when the market crashes, and especially on exchanges without huge amounts of organic buyers, a very large seller can cause a 30% drop for a short amount of time. And 15 minutes to recover when you have catalysts like FTX & the 3AC debacle is of course incredibly harsh terms.
        </p>
        <p>
        Regardless, we needed a catalyst and so reluctantly I paid the fees & security deposit and we listed on KuCoin. The initial pump was good… what didn’t help is the fact that the wallet address they gave us to make payment on was evidently tracked and so we saw a huge amount of people front running the listing, meaning on the day of listing the inevitable “sell the news” event happened… and we were back where we started. I thought maybe Huobi could provide some exposure to China and open up new buyers for us & so another $500,000 later, we listed on Huobi. It didn’t help, and within 6 weeks of our KuCoin & Huobi  listing, even with the pump in between, my token hit new lows.
        </p>
        <p>
        We also secured a listing vote on another prominent CEX. In the terms agreed, any malicious behaviour or breaking of the rules, forfeited a tokens shot and place in the competition. Well, we showed the exchange screenshots from our competitors telegram where they were actively coordinating FUD against our project. We expected like any reasonable people that this would fall under the malicious behaviour rules. But we were told no. We were then contacted by a marketing firm offering to help us with votes… they said they had a great relationship with the listing managers and could help. Well, because I’m not a cheat or a scammer, I relayed this information to the CEX & they didn’t respond at all nor ask for any other information.
        </p>
        <p>
        Now for those of you with some maths capabilities, you may now be seeing why this experience left me on the verge of taking my own life. If you add up the numbers above, you get;
</p>
<p>
Total Raised (including what we didn’t have access to for over a year) $2,300,000.
</p>
<p>
Expenses listed so far:<ul>
<li>First two exchanges - $500,000</li>
<li>Marketing Sponsor - $300,000</li>
<li>Buybacks - $878,723.11</li>
<li>KuCoin* - $500,000</li>
<li>Huobi (HTX) - $500,000</li>
<li>Total so far: $2,678,723.11</li>
</ul></p>
<p><em>
*we got lucky and only 50% of our security deposit with KuCoin got stolen</em>
        </p>
        <p>
        I have consistently had a team of over 10 full time staff and to this day my monthly outgoings including basic web development, tax, salaries & other work related expenses add up to $50,000 a month. So let's multiply that by 36 to get 3 years worth of expenses and that comes to an additional $1,800,000. Now if we add blockchain & protocol development specific work on top, we have another $2,000,000.
        </p>
        <p>
        So, to recap, I have spent, as a minimum, considering I haven't even included KOLs or any etherscan ads or anything into the above, $6,478,723.11 on my protocol. If you minus fund raises, that is $4,178,723.11 that I have earnt, from actually having a protocol that has a utility & some revenue, that I have invested back into my business. I lived in the same property as I did when I started crypto, until I got divorced. I have never bought a lambo or any other ridiculous expensive item, and what benefit have I got from not putting that $4,178,723.11 into my pocket?
        </p>
        <p>
        My reputation? 
        </p>
        <p>
        I am constantly harassed and called a scammer due to my token going down, so I don’t have that.
        </p>
        <p>
        My work life balance? I don’t have a life. I only work.
        </p>
        <p>
        My health? Absolutely not.
        </p>
        <p>
        People wonder why there are no honest devs in crypto… yet how do they treat the honest developers? I reinvested twice the funds I raised and I am treated worse than some outright scammers. If you show your face, people go after your family, if you share your background, people message your ex work colleagues. Exchanges don’t make it any better. We have been told by multiple exchanges the following:
        </p>
        <p><ul>
       <li> “Team should refrain from making statements about the price of the token”</li>
      <li>“Token is too reliant on the team, i.e. if anything happens to the team, the token would be affected.”</li>
</ul></p>
        <p>
        Now, exchanges are saying this for the right reasons, securities laws and decentralization… but what happens when you reduce your visibility as a team? “Dev abandoned project” what happens if you never mention your token? People get distracted by other shiny new things. In crypto “scams pump the hardest”. That is purely down to the fact that there is no incentive at all to be honest in crypto. If you take the moral high ground, don’t pay for trending, don’t pay for listings, CMC, CoinGecko, CEXs, Trust Wallet icon, the list is endless! What happens? You lose out to all of those that will play dirty.
        </p>
        <p>
        Running an honest project now in crypto is like entering a bodybuilding competition, but everybody else is on steroids and you’ve just got your little protein shake & a lunchbox full of beans. So it's for this reason that mugs like me give it absolutely everything they can, throw everything at it, and we are still knocked back down every single time. We are blamed for every negative and people just expect the listings, the KOLs, the marketing. Nobody stops to think, “where has the money come from for that? 
        </p>
        <p>
        So this is what got me here. Poor, depressed and on the verge of suicide because I built for an industry that everybody told me they wanted. But guess what? People talk shit and they vote with their money. It doesn’t take a genius to see what people really want to buy as it's just whatever meme or quick flip they can make or gamble on.
        </p>
        <p>
        So why am I writing this and why haven’t I just fucking killed myself already and got it over with? Well, the good thing about reaching rock bottom is that, once there, the only way you can go is up. 
        </p>
        <p>
        So! I am going to do what I am good at. Building decentralized infrastructure to try and help people. Starting with the honest devs.
        </p>
        <p><strong><u>The Goal</u></strong></p>
        <p>
Create a DAO composed of both experienced developers, advisors and professional investors whose experience can be drawn upon to support, connect and help projects who, through no fault of their own, have struggled to maintain traction or support in this market.
</p>
<p><strong><u>The Token</u></strong></p>
<p>
Total Supply*: 279,959,501
</p>
<p>
Distribution: 90% to Juicebox DAO Event
</p>
<p>
Airdrop**: 10% for Revivedrop
</p>
<p><em>
*Number of Ethereum unique addresses on 21st August 2024
</em>
</p>
<p><em>
**any wallet that has minted and deployed a token (3 months ago), maintained DEX liquidity, built utility and maintained social media management throughout the tokens lifespan, but suffered a 90% plus drawdown from all-time high. Can sign up for the Revivedrop. 
</em>
</p>
<p>
Revivedrop distribution = 10% of supply/approved deployer wallets = X. 
</p>
<p>
25% X goes to deployer wallet, 75% X distributed to token holders minus smart contract holders (such as staking or uniswap holders) & centralized exchange wallets. If an exchange agrees to list our token of their own volition & without fees, then we are happy to extend airdrop to these projects token holders who hold on CEX also.
</p>
<p>
<strong><u>The Ethereum raised</u></strong>
</p>
<p>
The team will not be assigned tokens in order to maintain decentralization of the voting system and to prevent any token sale pressure. For this reason 20% of the Ethereum raised up to the first 100 Eth will go to the projects development team to build out the platform and DAO functions as described below.
</p>
<p>
Any ethereum raised in excess of that, only 10% will be assigned to the project developers. All remaining Ethereum will be held and controlled by the DAO. 
</p>
<p>
<strong><u>The Questions & Challenges</u></strong>
</p>
<p><strong><em>
How does a project or developer get assistance from the DAO & how are opportunistic vs those with real need assessed? 
</em></strong>
</p>
<p>
<strong>The DAO</strong></p>
<p>
Holders of the DAO token can sign a TX & fill out a submission form to become a curator. Curators must be able to demonstrate in a voice Q&A, adequate crypto knowledge, adequate expertise & prove that their services they can provide voluntarily to projects will add sufficient value.
</p>
<p>
Holders of the DAO token will then vote on whether the individual & their wallet that submitted the form, can become a curator. Curators serve 6 months tenure before the DAO once again votes on whether their term should continue for another 6. 
</p>
<p>
<strong>The Projects</strong>
</p>
<p>
Any project which would like to opt in to the selection process must send a minimum of 1% of their token supply to the DAO treasury. The project must either be a minimum of 6 months old, or have a demonstrable event as to why help is needed sooner. This has to be something serious and provable, such as an exploit (only on audited contracts) or a hack (with proof of external hacker and not internal involvement). 
Upon receipt of the 1% of token supply, a dedicated Q&A discussion channel will be opened up in the DDDD Discord for holders of the token to ask anything they wish of the project seeking help. After 7 days, the holders of the DAO token will vote on whether or not to send the project to the curators. If rejected, the token supply is sent back to the project, if accepted, the project is sent to be reviewed by the curators.
</p>
<p>
<strong>The Curators</strong>
</p>
<p>
The curators as stated above are voted on by the DAO and act as elected representatives. Importantly, curators cannot outright reject any proposal sent to them by the DAO. They can only approve it, or send it back to the DAO for review, alongside their reasoning and feedback as to why it has been sent for further review. It is the job of the DAO to digest, interpret and think about these comments before voting once again on the project.
</p>
<p>
If rejected on the second vote, then the tokens are once again returned to the project. If approved again by the DAO, then the process repeats and the curators will once again, have another vote after listening to the reasoning as to why the DAO would like to see this project supported and helped by them and their expertise. The curators can once again reject the proposal, but this is for the last time. The third vote, if approved by the DAO, circumvents the curators and a third positive vote from the DAO is final.
</p>
<p>
<strong>How is the treasury controlled and how are funds spent or invested?</strong>
</p>
<p><strong>
The Curators
</strong>
</p>
<p>
The Curators have the ability to vote on already approved protocols receiving investment from the DAO. Any curator can put forward a proposal on protocols already approved and being advised to receive investment, this is then voted on by all of the curators and if approved, is sent to the DAO for voting. 
</p>
<p><strong>
The DAO</strong>
</p>
<p>
Like in the previous vote flow, the DAO has ultimate control and the first two rejections send the investment back to the curators for renegotiation. However, on third refusal by the DAO then the decision is final.
</p>
<p>
<strong>The Projects</strong>
</p>
<p>
In order to even ask for the possibility of investment, the project must have already gone through the original approval process and be currently receiving advice from the curators.
</p>
<p>
<strong>How does the DAO accrue value and what becomes of the 1%+ of tokens given?</strong>
</p>
<p>
<strong>The Projects</strong>
</p>
<p>
Projects need to remember that although our help will gain you connections, we cannot guarantee conversion of those introductions into results. The connections referred are  warm introductions. The team must impress them and not rely solely on the DAO for its success. We are a helping hand, if we were taking over responsibility we would be asking for much more than a minimum of 1%.
</p>
<p>
What we can guarantee however is that no tokens will be sold until the USD value of those tokens at the time you formally approached the DAO has at least doubled. At this time, your curators will always first approach you as the team for an OTC sale rather than through DEX or CEX. You are not obligated to fulfil this OTC request, but you have it as an option.
</p>
<p>
Projects can also opt in to our moonshot commitment program. This is not mandatory but the moonshot program may help your project pass the review processes. The moonshot commitment is an agreement that if your token increases by 1000% from when you approached the DAO, you agree to send an additional 0.2% of supply of your token, or Ethereum worth the equivalent of 0.2% of supply of your token to the DAO as a reward for our role in any project recovery. These tokens will be swapped for ethereum and that ethereum, or the ethereum sent in its place will be used to buyback and burn the DAO token.
</p>
<p><strong>
The DAO</strong>
</p>
<p>
Returns from advisory or investments will be paid out proportionally to stakers in Ethereum to prevent unnecessary sell pressure from returns in the token itself
</p>
<p><strong>
The Curators</strong>
</p>
<p>
The Curators agree to not make or come to any outside arrangements with proposal projects for services above and beyond the DAO, this includes token supply, kickbacks from exchange listings, kickbacks from VC investments or any other monetary reward. If you wish to become an advisor over and above your role in the DAO, you must allow the DAO to vote on this, and agree that no monetary rewards or reimbursement will come from the protocol until the DAO has seen breakeven on any investment or received a level of return from the project you are looking to join. Please note, we would like you to consider the above even if the agreement falls after your tenure with the DAO as curator. It is fine if you do not however be prepared it may be harder to get reelected if you choose to return to the DAO in the future.
</p>
`;

useEffect(() => {
  if (isTyping && currentIndex < fullText.length) {
    let timeout;
    if (fullText[currentIndex] === '<') {
      // Find the closing '>' of the tag
      const closingIndex = fullText.indexOf('>', currentIndex);
      if (closingIndex !== -1) {
        timeout = setTimeout(() => {
          setDisplayedText(prevText => prevText + fullText.slice(currentIndex, closingIndex + 1));
          setCurrentIndex(closingIndex + 1);
        }, 20);
      }
    } else {
      timeout = setTimeout(() => {
        setDisplayedText(prevText => prevText + fullText[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, 20);
    }

    return () => clearTimeout(timeout);
  } else if (currentIndex >= fullText.length) {
    setIsTyping(false);
  }
}, [currentIndex, fullText, isTyping]);
useEffect(() => {
  const cursorInterval = setInterval(() => {
    setShowCursor(prev => !prev);
  }, 10);

  return () => clearInterval(cursorInterval);
}, []);

const createMarkup = (html) => {
  return { __html: html + (isTyping && showCursor ? '|' : '') };
};

const handleSkip = () => {
  setDisplayedText(fullText);
  setCurrentIndex(fullText.length);
  setIsTyping(false);
};

return (
  <div className="blog-container">
    <div className="header-container">
      <img src={`${process.env.PUBLIC_URL}/HonestDAOTrans.png`} alt="HonestDAO Logo" className="blog-logo" />
      {isTyping && (
        <button className="skip-button" onClick={handleSkip}>
          Skip Animation
        </button>
      )}
    </div>
    <h1 className="blog-title">Honest DAO</h1>
    <p className="blog-author">By an Honest Developer | September 13, 2024</p>
    <div 
      className="blog-content"
      dangerouslySetInnerHTML={createMarkup(displayedText)}
    />
  </div>
);
}

export default App;